import { CategoryTypes } from '../categories';

export interface CategoryServicesRes {
	stock_available?: number;
	description?: string;
	masters?: number[];
	category_id?: number;
	currency: string;
	id: number;
	master_id: number;
	position: number;
	price: string;
	price_to?: string;
	service_time: string;
	title: string;
	title_ru: string;
	type: CategoryTypes;
	isChecked?: boolean;
	time: string;
	pedicure_master?: number;
	rent_time?: string;
	img?: string;
	vat?: number;
}
