import { Injectable, inject } from '@angular/core';
import { IRestOptions } from '../../interfaces';
import { OrdersService } from '../../services';
import { FormEffects, PagesEffects } from '../../store';

export const createOrdersFormName = 'createOrdersFormName';
export const getOrderPath = 'getOrderPath';
export const getOrdersPathName = 'getOrdersPathName';
export const getAdminOrdersPathName = 'getAdminOrdersPathName';

export const editOrderForm = 'editOrderForm';

@Injectable()
export class OrdersEffects {
	private readonly formEffects = inject(FormEffects);
	private readonly pagesEffects = inject(PagesEffects);
	private readonly ordersService = inject(OrdersService);

	public readonly $createOrders = this.formEffects.getSendFormEffect(createOrdersFormName, formState =>
		this.ordersService.createOrders(formState.formData),
	);

	public readonly loadAdminOrders$ = this.pagesEffects.getLoadPageEffect(getAdminOrdersPathName, ({ reqData }) => {
		return this.ordersService.getAdminOrders(reqData);
	});

	public readonly loadAdminOrdersPush$ = this.pagesEffects.getLoadPagePushEffect(
		getAdminOrdersPathName,
		({ page, reqData }: IRestOptions) => {
			return this.ordersService.getAdminOrders({ page, ...reqData });
		},
	);

	public readonly loadOrders$ = this.pagesEffects.getLoadPageEffect(getOrdersPathName, ({ reqData }) => {
		return this.ordersService.getOrders(reqData);
	});

	public readonly loadOrdersPush$ = this.pagesEffects.getLoadPagePushEffect(getOrdersPathName, ({ page, reqData }: IRestOptions) => {
		return this.ordersService.getOrders({ page, ...reqData });
	});

	public readonly editOrders$ = this.formEffects.getSendFormEffect(editOrderForm, formState => {
		return this.ordersService.editOrder(formState.formData);
	});

	public readonly loadOrder$ = this.pagesEffects.getLoadPageEffect(getOrderPath, ({ reqData }) => {
		return this.ordersService.getOrder(reqData);
	});
}
