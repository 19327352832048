export interface IPrinter {
	set_order_date?: boolean;
	id: number;
	title: string;
	printer_ip: string;
	address: string;
	vat_number: string;
	phone: string;
	user_id: number;
	website: string;
	last_receipt: {
		id: number;
		title: string;
		date: string;
		price: number;
		currency: string;
		qty: number;
	};
}
