// facebook-pixel.service.ts
import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CardModelsEnum } from '@solar/enums';

declare global {
	interface Window {
		fbq;
	}
}

@Injectable({ providedIn: 'root' })
export class FacebookPixelService {
	private readonly pixelId = '736513875263687';
	private isInitialized = false;

	constructor(
		private readonly router: Router,
		private readonly ngZone: NgZone,
	) {
		this.initializeFbqQueue();
	}

	private initializeFbqQueue(): void {
		if (typeof window.fbq !== 'function') {
			window.fbq = function (...args: any[]) {
				window.fbq.q.push(args);
			};
		}

		window.fbq.q = window.fbq.q || [];
		window.fbq.loaded = true;
		window.fbq.version = '2.0';
	}

	public initialize(): void {
		// if (this.isInitialized || !environment.production) return;

		this.loadScript(() => {
			this.ngZone.runOutsideAngular(() => {
				window.fbq('init', this.pixelId);
				this.trackPageView();
				this.setupRouterTracking();
				this.isInitialized = true;
			});
		});
	}

	// В сервисе
	public trackDynamicPageView(pageData: { title: string; category: string }): void {
		window.fbq('track', 'PageView', {
			page_title: pageData.title,
			content_category: pageData.category,
		});
	}

	private loadScript(onLoad: () => void): void {
		const script = document.createElement('script');
		script.async = true;
		script.defer = true;
		script.src = 'https://connect.facebook.net/en_US/fbevents.js';

		script.onload = () => {
			onLoad();
		};

		script.onerror = error => {
			console.error('Facebook Pixel script load failed:', error);
		};

		document.head.appendChild(script);
	}

	public trackPageView(customData?: {
		pageTitle?: string;
		pagePath?: string;
		referrer?: string;
		customParameters?: { [key: string]: any };
	}): void {
		const defaultData = {
			page_title: document.title,
			page_location: window.location.href,
			page_path: window.location.pathname,
			referrer: document.referrer,
		};

		const mergedData = {
			...defaultData,
			...customData,
			...(customData?.customParameters || {}),
		};

		window.fbq('track', 'PageView', mergedData);
	}

	public trackAddToCart(product: { id: string; name: string; price: string; currency?: string }): void {
		window.fbq('track', 'AddToCart', {
			content_ids: [product.id],
			content_name: product.name,
			value: product.price,
			currency: product.currency || 'USD',
			content_type: 'product',
		});
	}

	private setupRouterTracking(): void {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.trackPageView();
			}
		});
	}

	public trackPurchase(orderData: any, model: CardModelsEnum): void {
		// Группируем товары по ID для подсчёта quantity
		const itemsMap = new Map<string, number>();

		orderData.orders.forEach((item: any) => {
			const itemId = item.id.toString();
			itemsMap.set(itemId, (itemsMap.get(itemId) || 0) + 1);
		});

		// Формируем содержимое заказа
		const contents: Array<{ id: string; quantity: number }> = [];
		const content_ids: string[] = [];
		let totalValue = 0;

		itemsMap.forEach((quantity, itemId) => {
			const item = orderData.orders.find((i: any) => i.id.toString() === itemId);
			contents.push({
				id: itemId,
				quantity: quantity,
			});
			content_ids.push(itemId);
			totalValue += parseFloat(item.price) * quantity;
		});

		// Формируем параметры события
		const eventParams = {
			value: totalValue,
			currency: orderData.orders[0]?.currency || 'EUR',
			contents: contents,
			content_ids: content_ids,
			content_type: model,
			order_id: this.generateOrderId(), // Ваша функция генерации ID
			num_items: orderData.orders.length,
			delivery_method: orderData.pickup ? 'pickup' : 'delivery',
			customer_name: orderData.name,
			// customer_phone: orderData.code + orderData.phone,
		};

		// Отправляем событие
		window.fbq('track', 'Purchase', eventParams);
	}

	private generateOrderId(): string {
		return `ORDER-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
	}
}
