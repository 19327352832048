import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OnlineWsService } from '@solar/core/src';
import { take } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { IAppState } from '../../interfaces';
import { getUser, LoadPage, selectPageByName } from '../../store';

@Injectable({
	providedIn: 'root',
})
export class AppInitService {
	public readonly authService = inject(AuthService);
	public readonly store: Store<IAppState> = inject(Store);
	private readonly onlineWsService: OnlineWsService = inject(OnlineWsService);

	public appInit(): Promise<any> {
		this.onlineWsService.connectUser();

		if (!this.authService?.principal) {
			return Promise.resolve();
		}

		return new Promise<void>(resolve => {
			this.store
				.select(selectPageByName(getUser))
				.pipe(take(1))
				.subscribe(user => {
					if (!user) {
						if (this.authService?.principal?.id) {
							this.store.dispatch(LoadPage({ path: getUser, reqData: { id: this.authService.principal.id } }));
						}

						resolve();
					}
				});
		});
	}
}
