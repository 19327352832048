import { CategoryTypes } from '@solar/core/src/store/categories';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';

export interface UserRes {
	last_seen?: string;
	birth_date?: string;
	categories?: string;
	user_name?: string;
	id: number;
	name?: string;
	email?: string;
	password?: string;
	money?: any;
	online?: any;
	role?: UserRolesEnum | null;
	master_id?: number;
	first_name?: string;
	last_name?: string;
	services?: string;
	phone?: string;
	code?: string;
	img?: string;
	first_name_ru?: string;
	services_ru?: any;
	average_rating?: string;
	review_count?: string;
	all_categories?: { id: number; title: string; title_ru: string }[] | null;
	country?: string;
	city?: string;
	online_payments?: boolean;
	auto_order_confirm?: boolean;
}

export interface MasterInfo extends UserRes {
	auto_order_confirm?: boolean;
	show_client_contacts?: boolean;
	is_active?: boolean;
	isSelected?: boolean;
	services: CategoryTypes;
	managers?: number[];
	type?: CategoryTypes;
	printer_active?: boolean;
	statistics_active?: boolean;
}
