import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { OrdersStatsDto } from '../../rest/orders/orders-stats.dto';
import { OrdersService } from '../../rest/orders/orders.service';
import { IStatisticsReqData } from './statistics-state.interface';

@Injectable({
	providedIn: 'root',
})
export class StatisticStateService {
	private readonly restService = inject(OrdersService);

	public res: WritableSignal<OrdersStatsDto> = signal(null);

	public async fetchData({ month, year, masters, shop_id }: IStatisticsReqData): Promise<void> {
		const res = await lastValueFrom(this.restService.getStats({ month, year, masters, shop_id }));

		this.res.set(res);
	}
}
