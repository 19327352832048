import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Socket from '@vendus/sockets-for-cordova';
import { formatISO } from 'date-fns';
import { lastValueFrom, Observable } from 'rxjs';
import { ToastService } from '../../toast';
import { IPrinter } from './printers.dto';

@Injectable({
	providedIn: 'root',
})
export class PrintersService {
	constructor(
		private readonly http: HttpClient,
		private readonly toastService: ToastService,
	) {}

	public addPrinter(body: IPrinter): Observable<IPrinter> {
		return this.http.post<IPrinter>(`/printers`, body);
	}

	public editPrinter(body: IPrinter): Observable<IPrinter> {
		return this.http.patch<IPrinter>(`/printers`, body);
	}

	public async getPrintersAsync(): Promise<IPrinter[]> {
		return await lastValueFrom(this.http.get<IPrinter[]>(`/printers`));
	}

	public async printTheBill(
		order_id: number,
		printInPrinter = true,
	): Promise<{ message: string; results: { receiptData: Uint8Array; printer: string }[] }> {
		try {
			const clientTime = formatISO(new Date());

			const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

			const data = await lastValueFrom(
				this.http.post<{ message: string; results: { receiptData: Uint8Array; printer: string }[] }>(`/printers/bill`, {
					order_id,
					clientTime,
					timezone,
				}),
			);

			if (printInPrinter) {
				data.results.forEach((result: any) => {
					const receiptData = new Uint8Array(result?.receiptData?.data);
					// Открываем соединение с принтером

					if (receiptData) {
						const socket = new Socket();

						socket.open(
							result?.printer,
							9100,
							() => {
								void this.toastService.presentToast(`Connected to printer ${result?.printer}`);

								socket.write(
									receiptData,
									() => {
										void this.toastService.presentToast('Receipt printed successfully');
									},
									() => {
										void this.toastService.presentToast('Error sending data');
									},
								);
							},
							error => {
								void this.toastService.presentToast(error?.message);
							},
						);
					}
				});
			}

			return data;
		} catch (error) {
			void this.toastService.presentToast(error?.message);
		}
	}

	public async deletePrintersAsync(id: number): Promise<IPrinter[]> {
		return await lastValueFrom(this.http.delete<IPrinter[]>(`/printers/${id}`));
	}
}
