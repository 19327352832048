import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { App } from '@capacitor/app';
import { Camera } from '@capacitor/camera';
import { Keyboard } from '@capacitor/keyboard';
import { IonicModule, IonTextarea, ModalController, Platform } from '@ionic/angular';
import { IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { IAppState, MessagesWsService, timeoutUtil } from '@solar/core/src';
import { ChatMembersStateService } from '@solar/core/src/';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { IChat, IMessage } from '@solar/core/src/interfaces';
import { addMessage, getUser, LoadPage, selectPageByName, SendForm } from '@solar/core/src/store';
import { selectFormByName } from '@solar/core/src/store/forms/selectors';
import { FullNamePipe } from '@solar/shared/pipes';
import { distinctUntilChanged, Subject, Subscription, takeUntil } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { OnlineLastSeenComponent } from '../../../components/statefull/online-last-seen/online-last-seen.component';
import { AvatarComponent } from '../../../components/stateless/avatar/avatar.component';
import { HeaderComponent } from '../../../components/stateless/header/header.component';
import { FormConnectDirective, ShowExceptMeDirective } from '../../../directives';
import { ChatModalListComponent } from '../chat-modal-list/chat-modal-list.component';
import { ChatsService } from './../../../../../core/src/services/rest/chats/chats.service';
import { MessagesService } from './../../../../../core/src/services/rest/messages/messages.service';
import { MessagesStateService } from './../../../../../core/src/services/signals/messages-state/messages-state.service';
import { UnreadMessagesStateService } from './../../../../../core/src/services/signals/messages-state/unread-messages-state.service';
import { createChat } from './../../../../../core/src/store/chats/chats.effects';
import { ChatActionsComponent } from './chat-actions/chat-actions.component';
import { ChatModalFilesComponent } from './chat-modal-files/chat-modal-files.component';

@Component({
	selector: 'hb-chat-modal',
	templateUrl: './chat-modal.component.html',
	styleUrls: ['./chat-modal.component.scss'],
	imports: [
		IonicModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		ChatModalListComponent,
		HeaderComponent,
		ShowExceptMeDirective,
		FormConnectDirective,
		AvatarComponent,
		FullNamePipe,
		RouterLink,
		ChatModalFilesComponent,
		OnlineLastSeenComponent,
		ChatActionsComponent,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatModalComponent implements OnInit, OnDestroy {
	private readonly activatedRoute = inject(ActivatedRoute);
	private readonly modalCtrl = inject(ModalController);
	private readonly fb: FormBuilder = inject(FormBuilder);
	private readonly store: Store<IAppState> = inject(Store);
	public readonly webSocketService: MessagesWsService = inject(MessagesWsService);
	private readonly platform: Platform = inject(Platform);
	public readonly authService: AuthService = inject(AuthService);
	private readonly ch: ChangeDetectorRef = inject(ChangeDetectorRef);
	private readonly route: ActivatedRoute = inject(ActivatedRoute);
	private readonly messagesState: MessagesStateService = inject(MessagesStateService);
	private readonly chatsService: ChatsService = inject(ChatsService);
	private readonly chatMembersStateService: ChatMembersStateService = inject(ChatMembersStateService);
	private readonly unreadMessagesStateService: UnreadMessagesStateService = inject(UnreadMessagesStateService);
	private readonly messagesService: MessagesService = inject(MessagesService);

	public formName: string = addMessage;

	public form: FormGroup;

	@ViewChild('content') private readonly content: IonContent;
	@ViewChild('textarea') private readonly textarea: IonTextarea;

	@Input() public categoryId: number;
	@Input() public chatMember: IChat;

	protected subscription = new Subscription();

	protected typingSignal = this.webSocketService.typingMessageSignal;

	public isTyping = false;
	private readonly destroy$ = new Subject<void>();

	public scrollPercentage = 0;

	public imagePreviews: { webPath: string; file: File }[] = [];

	public isOpenChatModalFiles = false;

	@ViewChild(ChatModalListComponent) private readonly chatList: ChatModalListComponent;

	protected JSON: JSON = JSON;

	public replyMessage: IMessage;

	public currentCallCount = 0;
	public maxRecursiveCalls = 5;

	constructor() {
		effect(
			() => {
				const user_id = this.chatMembersStateService.chatData()?.user_id;

				if (this.webSocketService.sendMessageSignal()) {
					void this.unreadMessagesStateService.load().then();
				}

				if (user_id && this.webSocketService.isUserInChat(user_id)) {
					const messages = this.messagesState.messages()?.data;
					this.form.patchValue({ is_read: true });

					if (messages?.length) {
						const isSomeUnread = messages.filter(message => !message.is_read);

						if (isSomeUnread.length) {
							this.webSocketService.setMessagesRead(isSomeUnread);
						}
					}
				} else {
					this.form.patchValue({ is_read: false });
				}
			},
			{ allowSignalWrites: true },
		);

		this.webSocketService.initialize();
	}

	public async cancel(): Promise<void> {
		await this.modalCtrl.dismiss();
	}

	public ngOnInit(): void {
		this.form = this.fb.group({
			receiver: [null, Validators.required],
			message: [null, Validators.required],
			files: [null],
			is_read: [false],
			chat_id: [null, Validators.required],
			is_group: [false],
			reply_to: [null],
		});

		this.categoryId = this.activatedRoute.snapshot.params['userId'];
		this.getDataFromQueryParams();

		this.listenKeyboard();

		const valueChanges$ = this.form.get('message').valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy$));

		valueChanges$.pipe(takeUntil(this.destroy$)).subscribe(() => {
			if (!this.isTyping) {
				this.isTyping = true;
				this.sendTypingInfo();
				this.ch.detectChanges();
			}
		});

		valueChanges$.pipe(debounceTime(3000), takeUntil(this.destroy$)).subscribe(() => {
			this.isTyping = false;
			this.webSocketService.addTyping(null);
			this.ch.detectChanges();
		});

		this.appPauseResume();
	}

	public appPauseResume(): void {
		void App.addListener('pause', () => {
			this.webSocketService.removeUserFromChat(this.authService?.principal?.id);
		});

		void App.addListener('resume', () => {
			this.webSocketService.addUserToChat(this.authService?.principal?.id);
		});
	}

	public getChatIdAndJoin(): void {
		this.onLoadChatId();
		this.setChatIdAndJoin();
	}

	public ionViewWillEnter(): void {
		this.form.get('receiver').setValue(this.categoryId);
	}

	public async onMessagesLoaded(ev): Promise<void> {
		if (ev === 'firstLoad') {
			await timeoutUtil();
		}

		this.scrollToBottomOnInit();
	}

	public getDataFromQueryParams(): void {
		if (this.currentCallCount >= this.maxRecursiveCalls) {
			console.warn('Максимальное количество рекурсивных вызовов достигнуто');
			return; // Прерываем выполнение, если достигнут лимит
		}

		this.currentCallCount++;

		this.chatMember = this.chatMembersStateService.chatData();

		if (this.chatMember) {
			if (this.chatMember?.chat_id) {
				this.setChatDataToFormAndJoin(this.chatMember.chat_id);
			} else {
				this.getChatIdAndJoin();
			}

			if (this.chatMember?.is_group) {
				void this.chatsService.getChatMember({ chat_id: this.chatMember.chat_id }).then(res => {
					this.chatMembersStateService.authorizedMember.set(res);
				});

				this.webSocketService.is_group.set(this.chatMember?.is_group);
			}
		} else {
			this.getChatIdAndJoin();
			this.loadAndSetChatData();
		}

		this.ch.detectChanges();
	}

	protected loadAndSetChatData(): void {
		this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(async params => {
			if ((params?.chat_id || this.webSocketService.chatId()) && this.categoryId) {
				this.chatMember = await this.chatsService.getChat({ chat_id: params?.chat_id, user_id: this.categoryId });
				this.chatMembersStateService.chatData.set(this.chatMember);

				this.getDataFromQueryParams();
			}
		});
	}

	public onLoadChatId(): void {
		if (this.chatMembersStateService.chatData()) {
			const { is_group, chat_id, id, user_id } = this.chatMembersStateService.chatData();

			const user_2_id = id || user_id;

			this.store.dispatch(
				SendForm({
					formName: createChat,
					formState: {
						formData: {
							user_1_id: this.authService.principal.id,
							user_2_id,
							is_group: !!is_group,
							chat_id,
						},
					},
				}),
			);
		} else {
			this.store.dispatch(
				SendForm({
					formName: createChat,
					formState: {
						formData: {
							user_1_id: this.authService.principal.id,
							user_2_id: this.categoryId,
							is_group: false,
							chat_id: null,
						},
					},
				}),
			);
		}
	}

	public setChatIdAndJoin(): void {
		this.store
			.select(selectFormByName(createChat))
			.pipe(
				takeUntil(this.destroy$),
				filter(res => !!res?.responseData?.data),
			)
			.subscribe(res => {
				const chat_id = res?.responseData?.data?.chat_id;

				if (chat_id) {
					this.setChatDataToFormAndJoin(chat_id);
				}
			});
	}

	public setChatDataToFormAndJoin(chat_id: string): void {
		this.form.patchValue({
			chat_id,
			is_group: this.chatMember?.is_group,
		});

		this.webSocketService.joinChat(chat_id);
	}

	private sendTypingInfo(): void {
		this.store
			.select(selectPageByName(getUser))
			.pipe(take(1))
			.subscribe(user => {
				if (!user) {
					this.store.dispatch(LoadPage({ path: getUser, reqData: { id: this.authService?.principal?.id } }));
					return;
				}

				this.webSocketService.addTyping(user.data);
			});
	}

	public onSubmit(): void {
		this.form.get('message').setValue('');
		void this.textarea.setFocus();
		this.replyMessage = null;
	}

	private listenKeyboard(): void {
		if (this.platform.is('capacitor')) {
			void Keyboard.addListener('keyboardWillShow', info => {
				this.adjustForKeyboard(info.keyboardHeight);
			});

			void Keyboard.addListener('keyboardDidShow', () => {
				this.resetUI();
				this.scrollToBottomOnInit();
			});

			void Keyboard.addListener('keyboardDidHide', () => {
				this.resetUI();
				this.scrollToBottomOnInit();
			});
		}
	}

	private adjustForKeyboard(keyboardHeight: number) {
		const content = document.querySelector('.some-content');
		if (content) {
			// Set a custom property for the keyboard height
			document.documentElement.style.setProperty('--keyboard-height', `${keyboardHeight}px`);
			content.classList.add('keyboard-visible'); // Add class to apply margin and transition
		}
	}

	// Reset UI adjustments when the keyboard hides
	private resetUI() {
		const content = document.querySelector('.some-content');
		if (content) {
			content.classList.remove('keyboard-visible'); // Remove class to reset margin with animation
		}
	}

	public onSuccessForm(): void {
		this.form.get('message').setValue('');
		this.isOpenChatModalFiles = false;
	}

	public scrollToBottomOnInit(): void {
		if (this.content) {
			void this.content.scrollToBottom(300);
		}

		this.chatList.isUserAtBottom = true;
	}

	public async contentScrolled(ev): Promise<void> {
		const scrollElement = await this.content.getScrollElement();
		const scrollPosition = ev.detail.scrollTop;
		const totalContentHeight = scrollElement.scrollHeight;

		this.scrollPercentage = scrollPosition / (totalContentHeight - ev.target.clientHeight) + 0.001;
	}

	public async onLoadFile(): Promise<void> {
		try {
			// Получение изображений с помощью камеры или фотогалереи
			const images = await Camera.pickImages({
				quality: 100,
			});

			this.imagePreviews = [];

			// Преобразуем каждое изображение в объект File
			for (const image of images.photos) {
				const webPath = image.webPath;

				// Получаем Blob с помощью fetch
				const response = await fetch(image.webPath);
				const blob = await response.blob();

				// Создаем объект File
				const file = new File([blob], `image.${image.format}`, { type: `image/${image.format}` });

				// Добавляем файл в массив
				this.imagePreviews.push({ webPath, file });
			}

			this.isOpenChatModalFiles = true;

			this.ch.detectChanges();
		} catch (error) {
			console.error('Error loading images:', error);
		}
	}

	public onFilesModalClosed(ev: { files: { webPath: string; file: File }[] | null; message: string }): void {
		this.chatList.isUserAtBottom = true;

		if (ev) {
			const files = ev.files.map(it => it.file);

			this.onSubmitForm({ files, message: ev.message });
		} else {
			this.isOpenChatModalFiles = false;
			this.imagePreviews = [];
		}
	}

	public onSubmitForm(opt?: { files: File[]; message: string }): void {
		this.store.dispatch(SendForm({ formName: this.formName, formState: { formData: { ...this.form.value, ...opt } } }));
	}

	public onReplyMessage(message: IMessage): void {
		this.replyMessage = message;
		this.form.patchValue({ reply_to: message?.id ? message?.id : null });
		this.ch.detectChanges();
	}

	public ngOnDestroy(): void {
		if (this.chatMember?.chat_id) {
			void this.messagesService.updateLastRead({ chat_id: this.chatMember?.chat_id }).then();
		}

		this.form.reset();
		this.subscription.unsubscribe();

		this.destroy$.next();
		this.destroy$.complete();

		this.webSocketService.removeAllListeners();

		if (this.platform.is('capacitor')) {
			void Keyboard.removeAllListeners();
		}

		void App.removeAllListeners();
		this.webSocketService.removeAllListeners();
	}
}
