import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { OnlineWsService } from '@solar/core/src';
import { AuthService } from '@solar/core/src/auth/auth.service';
import { DateAgoPipe } from '../../../pipes';

@Component({
	selector: 'hb-online-last-seen',
	templateUrl: './online-last-seen.component.html',
	styleUrls: ['./online-last-seen.component.scss'],
	imports: [CommonModule, IonicModule, DateAgoPipe, TranslateModule],
})
export class OnlineLastSeenComponent {
	@Input() public user: { id: number; last_seen: string };
	@Input() public ngClass: string = 'font-size-12';
	@Input() public color: string = 'medium';

	constructor(
		protected readonly onlineWsService: OnlineWsService,
		protected readonly authService: AuthService,
	) {}
}
