import { ChangeDetectionStrategy, Component, effect, EventEmitter, inject, input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ILoadDataState } from '@solar/core/src';
import { LoadPage, selectPageByName } from '@solar/core/src/store';
import { categoriesPageName, CategoriesRes } from '@solar/core/src/store/categories';
import { Subscription } from 'rxjs';
import { ServerTranslatePipe } from '../../../pipes/server-translate.pipe';
import { AvatarComponent } from '../../stateless';

@Component({
	selector: 'hb-dynamic-segment-services',
	templateUrl: './dynamic-segment-services.component.html',
	styleUrls: ['./dynamic-segment-services.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule, TranslateModule, ServerTranslatePipe, AvatarComponent],
})
export class DynamicSegmentServicesComponent implements OnInit, OnDestroy {
	public pathName = input<string>(categoriesPageName);
	public reqData = input();
	public filterIdName = input<string>('category_id');

	public categories = signal<ILoadDataState<CategoriesRes[]>>(null);

	@Output() public selected = new EventEmitter();

	private readonly store = inject(Store);

	public subs: Subscription;

	constructor() {
		effect(() => {
			this.store.dispatch(LoadPage({ path: this.pathName(), reqData: this.reqData() }));
		});
	}

	public ngOnInit(): void {
		this.subs = this.store.select(selectPageByName(this.pathName())).subscribe(res => {
			if (res) {
				this.categories.set(res);
			}
		});
	}

	public onSelect(ev): void {
		void this.selected.emit(ev);
	}

	public ngOnDestroy(): void {
		if (this.subs) {
			this.subs.unsubscribe();
		}
	}
}
