import { ChangeDetectorRef, Directive, inject, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '@solar/core/src';
import { selectPageByName } from '@solar/core/src/store';
import { getUser } from '@solar/core/src/store/user/user-effects';
import { UserRolesEnum } from '@solar/enums/user-roles.enum';
import { filter, Subject, takeUntil } from 'rxjs';

@Directive({
	selector: '[showOnlyFor]',
})
export class ShowOnlyForDirective implements OnInit, OnDestroy {
	@Input('showOnlyFor')
	public onlyFor?: UserRolesEnum[];

	private readonly onDestroy = new Subject<void>();
	private isViewCreated = false;

	private readonly templateRef: TemplateRef<any> = inject(TemplateRef);
	private readonly viewContainer: ViewContainerRef = inject(ViewContainerRef);
	private readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
	private readonly store: Store<IAppState> = inject(Store);

	public ngOnInit(): void {
		this.store
			.select(selectPageByName(getUser))
			.pipe(
				filter(res => !!res?.data),
				takeUntil(this.onDestroy),
			)
			.subscribe(user => {
				const shouldShowView = this.onlyFor?.includes(user.data.role);

				if (shouldShowView && !this.isViewCreated) {
					this.viewContainer.createEmbeddedView(this.templateRef, { $implicit: user });
					this.isViewCreated = true;
				} else if (!shouldShowView && this.isViewCreated) {
					this.viewContainer.clear();
					this.isViewCreated = false;
				}

				this.changeDetector.markForCheck();
			});
	}

	public ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}
}
