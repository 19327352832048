import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonicModule, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ModeService } from '@solar/core/src';
import { FacebookPixelService } from '@solar/core/src/services/facebook-pixel/facebook-pixel.service';
import { MessagingService } from '@solar/core/src/services/fcm/messaging.service';
import { UpdateForceService } from '@solar/core/src/services/updata-force';
import firebase from 'firebase/compat';
import { Subscription } from 'rxjs';
import { UnreadMessagesStateService } from './../../libs/core/src/services/signals/messages-state/unread-messages-state.service';
import MessagePayload = firebase.messaging.MessagePayload;

@Component({
	selector: 'hb-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [IonicModule],
})
export class AppComponent implements OnInit, OnDestroy {
	public message: MessagePayload;

	protected subscription = new Subscription();

	constructor(
		private readonly translateService: TranslateService,
		private readonly messagingService: MessagingService,
		private readonly toastCtrl: ToastController,
		private readonly platform: Platform,
		private readonly router: Router,
		private readonly updateForceService: UpdateForceService,
		private readonly zone: NgZone,
		private readonly modeService: ModeService,
		private readonly unreadMessagesStateService: UnreadMessagesStateService,
		private readonly pixelService: FacebookPixelService,
	) {
		this.pixelService.initialize();
		void this.modeService.loadThemePreference();
		this.initializeApp();

		void this.platform.ready().then(() => {
			void SplashScreen.hide().then();

			this.updateForceService.checkForUpdate();
		});
	}

	public ngOnInit(): void {
		this.setLang();
		this.listenForMessages();
	}

	private setLang(): void {
		const userLang = navigator.language;
		const lang = userLang?.includes('ru') || localStorage.getItem('lang')?.includes('ru') ? 'ru' : 'en';

		this.translateService.setDefaultLang(lang);
		this.translateService.use(lang);
	}

	public listenForMessages(): void {
		this.subscription.add(
			this.messagingService?.getMessages().subscribe(async (msg: any) => {
				if (!this.router.url.includes('inbox')) {
					void this.unreadMessagesStateService.load().then();
					await this.presentToast(msg.notification.title, msg.notification.body);
				}
			}),
		);
	}

	public async presentToast(header: string, message: string): Promise<void> {
		const toast = await this.toastCtrl.create({
			header,
			message,
			duration: 1500,
			position: 'top',
			icon: 'chatbox-ellipses-outline',
			positionAnchor: 'footer',
		});

		await toast.present();
	}

	private initializeApp(): void {
		void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
				const domain = 'hey-beauty.app';

				const pathArray = event.url.split(domain);

				// Get the last element with pop()
				const appPath = pathArray.pop();

				if (appPath) {
					void this.router.navigateByUrl(appPath);
				}
			});
		});
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
