import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetButton } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, ReceiptsService } from '@solar/core/src/';
import { IOrders } from '@solar/core/src/services/rest/orders/orders.dto';
import { PrintersService } from '@solar/core/src/services/rest/printers/printers.service';
import { UserRoleService } from '@solar/core/src/services/user-role/user-role.service';
import { OrderStatusesEnum } from '@solar/enums/order-statuses.enum';

export enum OrderActionsEnum {
	EDIT = 'edit',
	PRINT_RECEIPT = 'print_receipt',
	DOWNLOAD_RECEIPT = 'download_receipt',
	PAY = 'pay',
	CANCEL = 'cancel',
	DELETE = 'delete',
	ACCEPT = 'accept',
}

@Injectable({
	providedIn: 'root',
})
export class OrderActionService {
	constructor(
		private readonly translate: TranslateService,
		private readonly userRoleService: UserRoleService,
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly printersService: PrintersService,
		private readonly receiptsService: ReceiptsService,
	) {}

	public getOrderActions(order: IOrders): ActionSheetButton[] {
		const buttons: ActionSheetButton[] = [
			{
				text: this.translate.instant('EDIT'),
				data: {
					action: OrderActionsEnum.EDIT,
				},
				handler: () => {}, // Обработчик будет добавлен в компоненте
			},
			{
				text: this.translate.instant('CLOSE'),
				role: 'cancel',
				data: {
					action: OrderActionsEnum.CANCEL,
				},
			},
		];

		if (this.authService.principal?.printer_active) {
			buttons.unshift({
				text: this.translate.instant('PRINT_RECEIPT'),
				data: {
					action: OrderActionsEnum.PRINT_RECEIPT,
				},
				handler: async () => {
					await this.onPrint(order);
				},
			});

			buttons.unshift({
				text: this.translate.instant('DOWNLOAD_RECEIPT'),
				data: {
					action: OrderActionsEnum.DOWNLOAD_RECEIPT,
				},
				handler: async () => {
					await this.downloadReceipt(order);
				},
			});
		}

		if (order.order_status_id !== OrderStatusesEnum.PAID && order.order_status_id !== OrderStatusesEnum.CANCELLED) {
			buttons.unshift({
				text: this.translate.instant('Pay'),
				data: {
					action: OrderActionsEnum.PAY,
				},
			});

			buttons.push({
				text: this.translate.instant('CANCEL'),
				role: 'destructive',
				data: {
					action: OrderActionsEnum.DELETE,
				},
			});
		}

		if (
			(this.userRoleService.isAdminOrMaster && order.order_status_id === OrderStatusesEnum.IN_PROGRESS) ||
			(this.userRoleService.isAdminOrMaster && order.order_status_id === OrderStatusesEnum.PENDING_ACCEPTANCE)
		) {
			buttons.unshift({
				text: this.translate.instant('ACCEPT'),
				data: {
					action: OrderActionsEnum.ACCEPT,
				},
			});
		}

		return buttons;
	}

	public goToEditOrder(order: IOrders, routerLink?: string): void {
		if (this.userRoleService.isAdmin) {
			void this.router.navigate([routerLink ? routerLink : '/admin/edit-order', order.id], { state: { order: order } });
			return;
		}

		void this.router.navigate([routerLink ? routerLink : '/orders', order.id], { state: { order: order } });
	}

	public async onPrint(order: IOrders): Promise<void> {
		try {
			await this.printersService.printTheBill(order.id);
		} catch (error) {
			console.log(error.message);
		}
	}

	public async downloadReceipt(order: IOrders): Promise<void> {
		try {
			await this.receiptsService.onDownload({
				order_id: order.id,
			});
		} catch (error) {
			console.log(error.message);
		}
	}
}
