@if (categories()?.data?.length) {
	<ion-toolbar mode="ios" class="ion-no-padding">
		<ion-segment class="ion-margin-horizontal" (ionChange)="onSelect($event)" [scrollable]="true" value="all">
			<ion-segment-button value="all">
				{{ 'ALL' | translate }}
			</ion-segment-button>
			@for (k of categories()?.data; track $index) {
				<ion-segment-button [value]="k">
					<hb-avatar [data]="{ user_name: k.title, img: k.img, id: k.id }" size="small"></hb-avatar>
					{{ k | serverTranslate }}
				</ion-segment-button>
			}
		</ion-segment>
	</ion-toolbar>
}
