export interface OrdersStatsDto {
	labels: string[];
	success: boolean;
	data: {
		serviceTotals: {
			currency: string;
			service: string;
			totalAmount: string;
			totalCount: string;
		}[];
		barChart: {
			labels: string[];
			datasets: { labels: string; data: { total: number }[]; backgroundColor: string }[];
		};
		pieChart: {
			labels: string[];
			backgroundColor: string[];
			data: number[];
		};
	};
}
