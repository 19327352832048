import { Injectable, OnDestroy, signal, WritableSignal } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { isEmptyValue } from '../utils';

export interface IOnlineUser {
	userId: number;
	socketId: number;
}

@Injectable({
	providedIn: 'root',
})
export class OnlineWsService implements OnDestroy {
	protected online: WritableSignal<IOnlineUser[]> = signal([]);
	private onlineUsersSubscription!: Subscription;

	constructor(
		private readonly socket: Socket,
		private readonly authService: AuthService,
	) {
		this.subscribeToOnlineUsers();
	}

	public ngOnDestroy(): void {
		this.onlineUsersSubscription?.unsubscribe();
	}

	private subscribeToOnlineUsers(): void {
		this.onlineUsersSubscription = this.socket.fromEvent('online_users').subscribe({
			next: (users: IOnlineUser[]) => this.online.set(users),
			error: err => console.error('Error receiving online users:', err),
		});
	}

	public connectUser(): void {
		const userId = this.authService.principal?.id;
		if (!isEmptyValue(userId) && !this.isOnline(userId)) {
			this.socket.emit('add_online_user', userId);
		}
	}

	public isOnline(id: number): boolean {
		if (this.authService.principal?.id === id) {
			return false;
		}

		return this.online().some(user => user.userId === id);
	}

	public get onlineUsers(): IOnlineUser[] {
		return this.online();
	}

	public disconnectUser(): void {
		const userId = this.authService.principal?.id;
		if (userId !== undefined && userId !== null) {
			this.socket.emit('delete_online_user', userId);
		}
	}
}
